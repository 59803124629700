








import { Component, Vue } from "vue-property-decorator";
import Cam from "@/components/Municipalidad/Cam.vue";

@Component({
  components: {
    Cam
  }
})
export default class CamView extends Vue {}
